import React from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"
import { rhythm } from "../utils/typography"
import Layout from "../layouts/index.js"

const NoStyleLink = props => (
  <Link css={{ textDecoration: `none` }} {...props} />
)

export default ({ location }) => {
  return (
    <Layout location={location}>
      
      <h1>Unstable: The strangest time in history..</h1>
      <p>
          <NoStyleLink to="https://tech-stoic.github.io/introduction-v5">Introduction</NoStyleLink><br/><br/>       
          <h4>Part 1: The widening gaps...</h4>
          <NoStyleLink to="https://tech-stoic.github.io/uniquely-human-v3">Chapter 1: The Anthropocene</NoStyleLink><br/>
          <NoStyleLink to="">Chapter 2: Ideological lenses</NoStyleLink><br/>
          <NoStyleLink to="">Chapter 3: Rise of coded technologies</NoStyleLink><br/>
          <NoStyleLink to="">Chapter 4: The natural world begging our mercy</NoStyleLink><br/>
          <NoStyleLink to="">Chapter 5: Chaotic synergy</NoStyleLink><br/>
          <NoStyleLink to="">Chapter 6:</NoStyleLink><br/>
      </p>
    </Layout>
  )
}
